import React from "react";
import cx from "clsx";
import { navigate } from "gatsby";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { getSrc } from "gatsby-plugin-image";
import { useSoftRiseShadowStyles } from "@mui-treasury/styles/shadow/softRise";
import rehypeReact from "rehype-react";

import { Button } from "gatsby-theme-material-ui";

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: 5,
    margin: 10,
    maxWidth: 350,
    position: "relative",
  },
  cardContent: {},
  actions: {},
  img: {
    position: "absolute",
    width: "40%",
    bottom: 0,
    right: 0,
    display: "block",
  },
  shell: {
    position: "absolute",
    bottom: 0,
    right: 0,
    transform: "translate(70%, 50%)",
    borderRadius: "50%",
    backgroundColor: "rgba(71, 167, 162, 0.12)",
    padding: "60%",
    "&:before": {
      position: "absolute",
      borderRadius: "50%",
      content: '""',
      display: "block",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "-16%",
      backgroundColor: "rgba(71, 167, 162, 0.08)",
    },
  },
}));

export default function BlogCard({ node }) {
  const styles = useStyles();
  const shadowStyles = useSoftRiseShadowStyles();

  return (
    <>
      <Card className={cx(styles.card, shadowStyles.root)}>
        <CardMedia
          component="img"
          height="100"
          image={getSrc(node.frontmatter.image)}
          alt="deal image"
        />
        <CardContent className={cx(styles.cardContent)}>
          <Typography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
          >
            {node.frontmatter.overline}
          </Typography>
          <Typography gutterBottom variant="h4" component="div">
            {node.frontmatter.title}
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            {node.frontmatter.price}
          </Typography>
          <Typography
            align="justify"
            variant="body2"
            color="text.secondary"
          >
            {" "}
            {renderAst(node.excerptAst)}
          </Typography>
        </CardContent>
        <CardActions className={cx(styles.actions)}>
          <Button
            sx={{ marginTop: "auto" }}
            color="primary"
            role="link"
            variant="outlined"
            onClick={() => {
              console.log(node.frontmatter.slug);
              navigate(node.frontmatter.slug);
            }}
          >
            {`Read More...`}
          </Button>
          <div className={styles.shell} />
        </CardActions>
      </Card>
    </>
  );
}
