import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import BlogCard from "../components/BlogCard";
import Layout from "../components/Layout";

const useStyles = makeStyles(() => ({
  root: {
    width: 654,
    margin: 30,
  },
}));
const BlogsPage = ({
  data: {
    allMarkdownRemark: { edges: stays },
  },
}) => {
  const cardStyles = useStyles();
  return (
    <Layout pageName="blogs" className={cardStyles.root}>
      <Helmet>
        <title>Valle de Guadalupe Baja Blog</title>
      </Helmet>
      <Container maxWidth="md" sx={{ mt: 5 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          rowSpacing={1}
          spacing={3}
        >
          {stays.map((c, index) => (
            <Grid item key={index}>
              <BlogCard {...c} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Layout>
  );
};

export default BlogsPage;

export const BlogsPageQuery = graphql`
    query BlogsPageQuery {
        allMarkdownRemark(
            filter: {
              frontmatter: { type: { in: ["blog", "press"] } },
            },
            sort: { order: DESC, fields: frontmatter___idx }
        ) {
            edges {
                node {
                    htmlAst
                    excerptAst(pruneLength: 150)
                    frontmatter {
                        title
                        overline
                        type
                        slug
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;
